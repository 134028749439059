<template>
  <div class="edit">
    <div class="edit-content">
      <div class="base-info">
        <p class="edit-title">基本信息</p>
        <div class="edit-middle">
          <input-unit titleKey="机构名称" :initalTitleValue="hospitalTitle" iconKey="机构Logo" :initialIconURL="hospitalLogo"
            @onInputInput="onCompanyInputInput" @onUploadSucceed="onCompanyLogoUploadSucceed"></input-unit>
        </div>
      </div>
      <!-- <div class="patient-id">
        <p class="edit-title">病案号配置</p>
        <div class="edit-middle">
          <pid-switch-unit :isPatientIdRequired="isPatientIdRequired" :isPatientIdDisplay="isPatientIdDisplay"
            @onRequiredSwitchChange="onPatientIdRequiredChange"
            @onDisplaySwitchChange="onPatientIdDisplayChange"></pid-switch-unit>
        </div>
      </div> -->
    </div>



    <div class="edit-buttons">
      <div class="edit-buttons-container">
        <button class="edit-buttons-container-cancel" @click="onCancelClick">取消</button>
        <button class="edit-buttons-container-save" @click="onSaveClick">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import InputUnit from "@c/setting/organization-configuration/edit/input-unit.vue";
import PickUnit from "@c/setting/organization-configuration/edit/pick-unit.vue";
import PidSwitchUnit from "@c/setting/organization-configuration/edit/pid-switch-unit.vue";

export default {
  components: {
    InputUnit,
    PickUnit,
    PidSwitchUnit
  },

  props: {
    
    model: Object,
  },

  created() {
    this.hospitalTitle = this.initialHospitalTitle;
    this.hospitalLogo = this.initialHospitalLogo;
    this.reportTitle = this.initialReportTitle;
    this.reportSubtitle = this.initialReportSubtitle;
    this.reportLogo = this.initialReportLogo;
    this.currentPickedStandardIndex = this.initialPickedStandardIndex;
    this.currentPickedSuggestionIndex = this.initialPickedSuggestionIndex;
    this.isPatientIdDisplay = this.initialIsPatientIdDisplay;
  },

  data() {
    return {
      iconURL: require("@imgs/logo-placeholder.png"),
      currentPickedStandardIndex: 0,
      currentPickedSuggestionIndex: 0,
      hospitalTitle: "",
      hospitalLogo: "",
      reportTitle: "",
      reportSubtitle: "",
      reportLogo: "",
      isPatientIdRequired: false,
      isPatientIdDisplay: false
    };
  },

  computed: {
    initialHospitalTitle() {
      return this.model.hospitalName;
    },

    initialHospitalLogo() {
      if (this.model.hospitalLogo == undefined) {
        return require("@imgs/logo-placeholder.png");
      } else {
        return this.model.hospitalLogo;
      }
    },

    initialReportTitle() {
      return this.model.reportTitle;
    },
    initialReportSubtitle() {
      return this.model.reportSubtitle;
    },

    initialReportLogo() {
      if (this.model.reportLogo == undefined) {
        return require("@imgs/logo-placeholder.png");
      } else {
        return this.model.reportLogo;
      }
    },

    initialPickedStandardIndex() {
      return getObjectValues(CHILD_STANDDARD_TYPE_CODE).indexOf(
        this.model.reportStandard
      );
    },

    initialPickedSuggestionIndex() {
      return getObjectValues(REPORT_SUGGESTION_TYPE).indexOf(
        this.model.reportEvaluate
      );
    },

    initialIsPatientIdDisplay() { 
      return this.model.pollShowCaseNumber === '1' ? true : false;
    },

    standDartIndex() {
      return getObjectValues(CHILD_STANDDARD_TYPE_CODE)[
        this.currentPickedStandardIndex
      ];
    },

    suggestionIndex() {
      return getObjectValues(REPORT_SUGGESTION_TYPE)[
        this.currentPickedSuggestionIndex
      ];
    },
  },

  methods: {
    onCompanyInputInput(value) {
      this.hospitalTitle = value;
    },

    onReportInputInput(value) {
      this.reportTitle = value;
    },
    onReportSubInputInput(value) {
      this.reportSubtitle = value;
    },
    onStandardListItemClick(selectedIndex) {
      this.currentPickedStandardIndex = selectedIndex;
    },

    onSuggestionListItemClick(selectedIndex) {
      this.currentPickedSuggestionIndex = selectedIndex;
    },

    onCancelClick() {
      this.$emit("onCancelClick");
    },

    onSaveClick() {
      if (!this.hospitalTitle) {
        this.$toast.showRed("机构名称不能为空")
        return
      }
      const settingInfoModel = {
        hospitalTitle: this.hospitalTitle,
        hospitalLogo: this.hospitalLogo,
        pollShowCaseNumber: this.isPatientIdDisplay ? '1' : '0'
      };
      this.$emit("onSaveClick", settingInfoModel);
    },

    onCompanyLogoUploadSucceed(iconURL) {
      this.hospitalLogo = iconURL;
    },

    onReportLogoUploadSucceed(iconURL) {
      this.reportLogo = iconURL;
    },

    onPatientIdRequiredChange(val) {
      this.isPatientIdRequired = val;
    },
    onPatientIdDisplayChange(val) {
      this.isPatientIdDisplay = val;
    }
  },
};
</script>

<style lang="scss" scoped>

.edit {
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  
  .edit-content {
    display: flex;
      justify-content: space-between;
  }

  .base-info {
    
  }
  .patient-id {
    width: 400px;
  }

  &-title {
    color: black;
    font-size: rem(16);
    font-weight: bold;
    height: rem(22);
    margin-top: rem(16);
  }

  &-middle {
    margin-top: rem(18);
    display: flex;

    &-right {
      position: absolute;
      left: rem(618);
    }
  }



  &-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: rem(63);

    &-container {
      display: flex;
      &-cancel {
        color: #325FAC;
        font-size: rem(16);
        border: rem(1) solid #325FAC;
        border-radius: rem(4);
        width: rem(100);
        height: rem(40);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-save {
        color: white;
        background-color: #325FAC;
        font-size: rem(16);
        border: rem(1) solid #325FAC;
        border-radius: rem(4);
        width: rem(100);
        height: rem(40);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: rem(50);
      }
    }
  }
}
</style>